import { FC, useState } from 'react';

import { Alert, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InvalidAddressError } from 'src/modules/employee/utils/validateAddress';

import { useStyles } from './AddressSuggestions.style';

export interface AddressSuggestionsProps {
  error?: InvalidAddressError;
  onClick: (suggestion: InvalidAddressError['suggestions'][0]) => void;
}

const AddressSuggestions: FC<AddressSuggestionsProps> = ({
  error,
  onClick: handleClick,
}) => {
  const { t } = useTranslation(['cart', 'common']);

  const classes = useStyles();

  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);

  if (error?.type === 'error') {
    return (
      <Alert severity="error" className={classes.root}>
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      {error?.suggestions && (
        <Alert severity="success" className={classes.root}>
          <Typography variant="body2" style={{ marginBottom: 7 }}>
            {t('cart:did_you_mean')}
          </Typography>
          <ul>
            {error.suggestions.map((suggestion, index) => (
              <li key={index}>
                <Link
                  component="button"
                  onClick={() => {
                    handleClick(suggestion);
                    setIsSuggestionClicked(true);
                  }}
                  type="button"
                >
                  {suggestion.street} {suggestion.house_number}{' '}
                  {suggestion.building}, {suggestion.postal_code}{' '}
                  {suggestion.city}, {suggestion.country_code}
                </Link>
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {isSuggestionClicked && (
        <Alert severity="warning" sx={{ marginTop: 1 }}>
          This address was automatically synced. Always make sure to
          double-check the address validity before using lifecycle services.
        </Alert>
      )}
    </>
  );
};

export default AddressSuggestions;
