import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  AddressValidatorActionFail,
  AddressValidatorActionRequest,
  AddressValidatorActionReset,
  AddressValidatorActionSuccess,
  AddressValidatorListAction,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { AddressValidator } from 'src/types';
import { AddressFormikValues } from 'src/types/formikValues';
import axios, { ErrorResponse } from 'src/utils/axios';

export const validateAddress =
  (
    value: AddressFormikValues,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    AddressValidatorListAction
  > =>
  async dispatch => {
    dispatch<AddressValidatorActionRequest>({
      type: ActionTypes.ADDRESS_VALIDATOR_REQUEST,
    });

    try {
      const req = {
        country_code: value.country?.code,
        postal_code: value.postal_code,
        city: value.city,
        street: value.street,
        building: value.building,
        house_number: value.house_number,
      };

      const res = await axios.post<{ data: AddressValidator[] }>(
        `${apiConfig.url}/verify-address`,
        req,
      );

      const { data } = res.data;

      dispatch<AddressValidatorActionSuccess>({
        type: ActionTypes.ADDRESS_VALIDATOR_SUCCESS,
        payload: data,
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (error) {
          dispatch<AddressValidatorActionFail>({
            type: ActionTypes.ADDRESS_VALIDATOR_FAIL,
            payload: error,
          });
        }
      }
    }
  };

export const validateAddressReset = (): AddressValidatorActionReset => ({
  type: ActionTypes.ADDRESS_VALIDATOR_RESET,
});
