import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'src/assets/css/prism.css';
import 'src/assets/css/main.css';
import '@total-typescript/ts-reset/filter-boolean';
import { Auth0Provider } from '@auth0/auth0-react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Rollbar from 'rollbar';

import App from 'src/App';
import { apiConfig } from 'src/config';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import store from 'src/store';
import './i18n';
import { pAnalytic } from 'src/utils/posthog';

import ErrorBoundaryComponent from './components/ErrorBoundary';
import CrossIcon from './components/Icons/CrossIcon';

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: apiConfig.rollbar_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  exitOnUncaughtException: true,
  logLevel: 'error',
  uncaughtErrorLevel: 'error',
  environment: apiConfig.app_env,
  enabled: apiConfig.app_env !== 'local', //prevent rollbar on local env
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
    environment: apiConfig.app_env,
  },
};

if (process.env.NODE_ENV === 'production') {
  pAnalytic.init();
}

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Provider store={store}>
        <SettingsProvider>
          <SnackbarProvider
            dense
            maxSnack={5}
            style={{ fontFamily: 'Helvetica, Arial, sans-serif' }}
            action={snackbarId => (
              <a
                href="#"
                onClick={() => closeSnackbar(snackbarId)}
                style={{ marginTop: -2 }}
              >
                <CrossIcon
                  color="white"
                  style={{ cursor: 'pointer', color: 'white', marginRight: 10 }}
                />
              </a>
            )}
          >
            <Auth0Provider
              domain={apiConfig.auth0_domain ?? ''}
              clientId={apiConfig.auth0_client_id ?? ''}
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: apiConfig.auth0_audience,
              }}
              cacheLocation="localstorage"
            >
              <QueryClientProvider
                client={
                  new QueryClient({
                    defaultOptions: {
                      queries: {
                        refetchOnWindowFocus: false,
                        retry: 0,
                      },
                    },
                  })
                }
              >
                <ReactQueryDevtools initialIsOpen={false} />
                <ErrorBoundary fallbackUI={ErrorBoundaryComponent}>
                  <App />
                </ErrorBoundary>
              </QueryClientProvider>
            </Auth0Provider>
          </SnackbarProvider>
        </SettingsProvider>
      </Provider>
    </LocalizationProvider>
  </RollbarProvider>,

  document.getElementById('root'),
);
