import { FC } from 'react';

import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import { useStyles } from './PhoneInput.styles';

const PhoneInput: FC<PhoneInputProps> = ({ ...props }) => {
  const classes = useStyles();

  return (
    <ReactPhoneInput
      {...props}
      masks={{ ar: '..............' }}
      containerClass={classes.phoneInputContainer}
      inputClass={classes.phoneInputFormControl}
    />
  );
};

export default PhoneInput;
