import React, { ReactNode, useEffect, useState } from 'react';

import {
  Box,
  BoxProps,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import WorkwizeBanner from 'src/components/Icons/WorkwizeBanner';
import SettingsBlock from 'src/components-for-storybook/organisms/SettingsBlock/SettingsBlock';
import { User, colorSchemeV2 } from 'src/types';
import { RoleName } from 'src/types/role-name';

const styles = makeStyles(() =>
  createStyles({
    listItem: {
      padding: '5px 8px 5px 10px',
    },
    listItemHover: {
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0)',
      },
    },
  }),
);

type listItemInset<T> = {
  text: T;
  icon?: ReactNode;
  href?: string;
  onClick?: (active: T) => void;
};

export type listItem<T> = {
  type: 'subheader' | 'menuItem' | 'expandableMenuItem';
  text: T;
  icon?: ReactNode;
  badge?: ReactNode;
  href?: string;
  onClick?: (active: T) => void;
  insets?: listItemInset<T>[];
};

interface ISideMenuBar<T> {
  companyLogo?: string;
  listItems: listItem<T>[];
  user: User;
  nextRole: RoleName;
  handleSwitchRole: () => void;
  handleLogout: () => void;
  colorScheme?: {
    textPrimary: string;
    textSecondary: string;
    drawer: {
      background: string;
    };
    budget: {
      background: string;
      textPrimary: string;
      textSecondary: string;
      textEmpty: string;
    };
    settingsBlock: {
      expandedBackground: string;
      collapsedBackground: string;
      textPrimary: string;
      hover: string;
    };
    active: {
      background: string;
      textPrimary: string;
    };
  };
}

const SideMenuBar = <T,>({
  companyLogo,
  listItems,
  user,
  nextRole,
  handleSwitchRole,
  handleLogout,
  colorScheme,
}: ISideMenuBar<T>) => {
  const [expandableMenuItemsOpen, setExpandableMenuItemsOpen] =
    useState<{ text: T; isOpen: boolean }[]>();

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(['common']);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [displayChevronBtn, setDisplayChevronBtn] = useState(false);
  const [settingsBlockPosition, setSettingsBlockPosition] = useState<
    'relative' | 'absolute'
  >('absolute');

  const activeIndicator = (isInset = false) => {
    return (
      <>
        {!isInset && (
          <Box
            border={`2px solid ${
              colorScheme?.active.background || colorSchemeV2.primary
            }`}
            position="absolute"
            height="80%"
            left={0}
            borderRadius="0 5px 5px 0"
          ></Box>
        )}
        <Box
          bgcolor={colorScheme?.active.background || colorSchemeV2.primaryDark}
          position="absolute"
          borderRadius={5}
          left={10}
          height="80%"
          width="93%"
        ></Box>
      </>
    );
  };

  const isDrawerResponsive = (innerWidth: number) => {
    if (innerWidth < 1280) {
      setIsDrawerOpen(false);
      setDisplayChevronBtn(true);
    } else {
      setIsDrawerOpen(true);
      setDisplayChevronBtn(false);
    }
  };

  const determinSettingsBlockPosition = (innerHeight: number) => {
    if (innerHeight < 750) {
      setSettingsBlockPosition('relative');
    } else {
      setSettingsBlockPosition('absolute');
    }
  };

  useEffect(() => {
    isDrawerResponsive(window.innerWidth);
    determinSettingsBlockPosition(window.innerHeight);
    window.addEventListener('resize', () => {
      isDrawerResponsive(window.innerWidth);
      determinSettingsBlockPosition(window.innerHeight);
    });
  }, []);

  return (
    <>
      {displayChevronBtn && (
        <Box
          position="absolute"
          top={15}
          zIndex={1201}
          style={{ transition: '0.2s', left: isDrawerOpen ? 260 : -10 }}
        >
          <IconButton
            size="small"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            style={{
              background: '#cccccc77',
              color: 'inherit',
            }}
          >
            {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
      )}
      <Drawer
        open={isDrawerOpen}
        variant="persistent"
        anchor="left"
        PaperProps={{
          style: {
            backgroundColor:
              colorScheme?.drawer.background || colorSchemeV2.primaryDarker,
            color: colorScheme?.textPrimary || colorSchemeV2.textLight,
            width: 275,
          },
        }}
      >
        <Box mt={3} pt={2} px={3} display="flex" alignItems="center">
          <Box maxHeight={55} maxWidth={150} overflow="hidden">
            {companyLogo &&
            companyLogo !== 'https://app.goworkwize.com/static/logo.svg' ? (
              <img src={companyLogo} alt="company logo" width="100%" />
            ) : (
              <WorkwizeBanner
                color={
                  colorScheme?.drawer.background === '#ffffff'
                    ? colorSchemeV2.primary
                    : 'currentColor'
                }
              />
            )}
          </Box>
        </Box>

        {user.role.name === 'employee' && (
          <Box
            borderRadius={5}
            bgcolor={
              colorScheme?.budget.background || colorSchemeV2.primaryDark
            }
            m={1}
            mt={3}
            p={2}
            display="flex"
            gridGap={5}
            flexDirection="column"
          >
            {Boolean(user.employee?.available_purchase_budget) &&
              Boolean(user.employee?.available_purchase_budget_formatted) && (
                <>
                  <Typography
                    variant="body2"
                    style={{
                      color:
                        colorScheme?.budget.textSecondary ||
                        colorSchemeV2.textSecondary,
                    }}
                  >
                    {t('common:input_label.monthly_budget')}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        Number(user.employee?.available_rent_budget ?? 0) <= 0
                          ? colorScheme?.budget.textEmpty
                          : colorScheme?.budget.textPrimary ||
                            colorSchemeV2.textLight,
                    }}
                  >
                    <Box component="span" fontWeight={700}>
                      {user.employee?.available_purchase_budget_formatted}
                    </Box>{' '}
                    (out of {user.employee?.total_purchase_budget_formatted})
                  </Typography>
                </>
              )}
            {Boolean(user.employee?.available_rent_budget) &&
              Boolean(user.employee?.available_rent_budget_formatted) && (
                <>
                  <Typography
                    variant="body2"
                    style={{
                      color:
                        colorScheme?.budget.textSecondary ||
                        colorSchemeV2.textSecondary,
                    }}
                  >
                    {t('common:input_label.purchase_budget')}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        Number(user.employee?.available_purchase_budget ?? 0) <=
                        0
                          ? colorScheme?.budget.textEmpty
                          : colorScheme?.budget.textPrimary ||
                            colorSchemeV2.textLight,
                    }}
                  >
                    <Box component="span" fontWeight={700}>
                      {user.employee?.available_rent_budget_formatted}
                    </Box>{' '}
                    (out of {user.employee?.total_rent_budget_formatted})
                  </Typography>
                </>
              )}
          </Box>
        )}

        <List>
          {listItems.map(listItem => {
            const isActive = location.pathname === listItem.href;

            if (listItem.type === 'subheader') {
              return (
                <Box
                  key={listItem.text as string}
                  pl={2}
                  pt={3}
                  color={
                    colorScheme?.textSecondary || colorSchemeV2.textSecondary
                  }
                >
                  <Typography variant="body2">{listItem.text}</Typography>
                </Box>
              );
            }

            if (listItem.type === 'expandableMenuItem') {
              const menuItemIsOpen =
                expandableMenuItemsOpen?.find(
                  ({ text }) => text === listItem.text,
                )?.isOpen ?? false;

              return (
                <>
                  <ListItem
                    button
                    key={listItem.text as string}
                    className={`${styles().listItem} ${styles().listItemHover}`}
                    style={{
                      color: isActive
                        ? colorScheme?.active.textPrimary
                        : colorScheme?.textPrimary ?? colorSchemeV2.textLight,
                    }}
                    onClick={() => {
                      const filteredItems = expandableMenuItemsOpen?.filter(
                        item => item.text !== listItem.text,
                      );

                      setExpandableMenuItemsOpen([
                        ...(filteredItems ?? []),
                        { text: listItem.text, isOpen: !menuItemIsOpen },
                      ]);
                    }}
                  >
                    {isActive && activeIndicator()}
                    <Box
                      sx={
                        {
                          transition: '0.4s',
                          '&:hover': {
                            backgroundColor:
                              colorScheme?.active.background ??
                              colorSchemeV2.primaryDark,
                          },
                        } as BoxProps
                      }
                      display="flex"
                      alignItems="center"
                      gridColumnGap={20}
                      width="100%"
                      zIndex={1}
                      color="inherit"
                      padding="6px 15px"
                      borderRadius={5}
                    >
                      <Box display="flex" alignItems="center">
                        {listItem.icon}
                      </Box>
                      <Box>
                        <Typography style={{ fontSize: '0.9rem' }}>
                          {listItem.text}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="end" width="100%">
                        {menuItemIsOpen ? (
                          <ExpandLess style={{ zIndex: 1 }} />
                        ) : (
                          <ExpandMore style={{ zIndex: 1 }} />
                        )}
                      </Box>
                    </Box>
                  </ListItem>
                  <Collapse in={menuItemIsOpen}>
                    <List style={{ paddingLeft: 30 }}>
                      {listItem.insets?.map(inset => {
                        const isInsetActive = location.pathname === inset.href;

                        return (
                          <ListItem
                            button
                            className={styles().listItemHover}
                            key={inset.text as string}
                            onClick={() => {
                              if (inset.onClick) {
                                inset.onClick(inset.text);
                              }
                              history.push(inset.href ?? '');
                            }}
                            style={{
                              padding: '5px 7px 5px 10px',
                            }}
                          >
                            {isInsetActive && activeIndicator(true)}
                            <Box
                              sx={
                                {
                                  transition: '0.4s',
                                  '&:hover': {
                                    backgroundColor:
                                      colorScheme?.active.background ??
                                      colorSchemeV2.primartDarkest,
                                  },
                                } as BoxProps
                              }
                              display="flex"
                              alignItems="center"
                              gridColumnGap={20}
                              width="100%"
                              zIndex={1}
                              color="inherit"
                              padding={1}
                              borderRadius={5}
                            >
                              <Box display="flex" alignItems="center">
                                {inset.icon}
                              </Box>
                              <Box>
                                <Typography
                                  style={{
                                    fontWeight: isInsetActive ? 600 : 'inherit',
                                    fontSize: '0.9rem',
                                  }}
                                >
                                  {inset.text}
                                </Typography>
                              </Box>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              );
            }

            return (
              <ListItem
                button
                key={listItem.text as string}
                className={`${styles().listItem} ${styles().listItemHover}`}
                style={{
                  color: isActive
                    ? colorScheme?.active.textPrimary
                    : colorScheme?.textPrimary ?? colorSchemeV2.textLight,
                }}
                onClick={() => {
                  if (listItem.onClick) {
                    listItem.onClick(listItem.text);
                  }
                  if (listItem.href) {
                    history.push(listItem.href);
                  }
                }}
              >
                {isActive && activeIndicator()}
                <Box
                  sx={
                    {
                      transition: '0.4s',
                      '&:hover': {
                        fontWeight: 600,
                        backgroundColor:
                          colorScheme?.active.background ??
                          colorSchemeV2.primartDarkest,
                      },
                    } as BoxProps
                  }
                  display="flex"
                  alignItems="center"
                  gridColumnGap={20}
                  width="100%"
                  zIndex={1}
                  color="inherit"
                  padding="6px 15px"
                  borderRadius={5}
                >
                  <Box display="flex" alignItems="center">
                    {listItem.icon}
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: isActive ? 600 : 'inherit',
                        fontSize: '0.9rem',
                      }}
                    >
                      {listItem.text}
                    </Typography>
                  </Box>
                  {listItem.badge && (
                    <Box marginLeft="auto">{listItem.badge}</Box>
                  )}
                </Box>
              </ListItem>
            );
          })}
        </List>

        <Box
          position={settingsBlockPosition}
          bottom={0}
          zIndex={1}
          width="100%"
        >
          <SettingsBlock
            user={user}
            nextRole={nextRole}
            handleSwitchRole={handleSwitchRole}
            handleLogout={handleLogout}
            colorScheme={{
              expandedBackground:
                colorScheme?.settingsBlock.expandedBackground ??
                colorSchemeV2.primaryDark,
              collapsedBackground:
                colorScheme?.settingsBlock.collapsedBackground ??
                colorSchemeV2.primaryDarker,
              textPrimary:
                colorScheme?.settingsBlock.textPrimary ??
                colorSchemeV2.textLight,
              hover:
                colorScheme?.settingsBlock.hover ?? colorSchemeV2.primaryDark,
            }}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default SideMenuBar;
