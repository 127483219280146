import Axios from 'axios';
import { z } from 'zod';

import axios from 'src/utils/axios';

import {
  AddressValidatorValidatorSchema,
  AddressValidatorType,
} from '../validators/addressValidatorValidator';

interface Address {
  building: string | null | undefined;
  city: string;
  country_code: string;
  house_number: string | null | undefined;
  postal_code: string;
  region: string | null | undefined;
  street: string;
  phone_number?: string | null | undefined;
}

export const compareResult = (
  address: Address,
  result: AddressValidatorType,
): boolean => {
  const building =
    result.HouseNumberAddition || result.Building || address.building || '';
  const city = result.City || '';
  const houseNumber = String(result.HouseNumber || '');
  const postalCode = result.PostalCode || '';
  const region = result.State || address.region || '';
  const street = result.Street || '';

  return (
    (address.building?.toLowerCase() ?? '') === building.toLowerCase() &&
    address.city.toLowerCase() === city.toLowerCase() &&
    (address.house_number?.toLowerCase() ?? '') === houseNumber.toLowerCase() &&
    address.postal_code.toLowerCase() === postalCode.toLowerCase() &&
    (address.region?.toLowerCase() ?? '') === region.toLowerCase() &&
    address.street.toLowerCase() === street.toLowerCase()
  );
};

export class InvalidAddressError extends Error {
  constructor(
    public readonly suggestions: Address[],
    public readonly type: 'suggestion' | 'error' = 'suggestion',
    message = 'Invalid address',
  ) {
    super(message);
    this.name = 'SuggestionsError';

    Object.setPrototypeOf(this, InvalidAddressError.prototype);
  }
}

export const validateAddress = async (address: Address) => {
  try {
    const { data } = await axios.post('/verify-address', address);
    const validationResult = z
      .array(AddressValidatorValidatorSchema)
      .safeParse(data.data);

    if (validationResult.success) {
      const results = validationResult.data;

      if (results.length === 0) {
        return;
      }

      if (results.some(result => compareResult(address, result))) {
        return;
      }

      const suggestions: Array<Address> = results.map(result => ({
        building: result.HouseNumberAddition || null,
        city: result.City || '',
        house_number: String(result.HouseNumber ?? ''),
        postal_code: result.PostalCode || '',
        region: result.State || null,
        street: result.Street || '',
        country_code: address.country_code,
        phone_number: address.phone_number,
      }));

      throw new InvalidAddressError(suggestions);
    }
  } catch (error) {
    if (Axios.isAxiosError(error)) {
      const data = error.response?.data as
        | {
            errors?: Array<{ errors?: Array<string> }>;
          }
        | undefined;
      const responseError = data?.errors?.[0]?.errors?.[0];

      if (typeof responseError === 'string') {
        throw new InvalidAddressError([], 'error', responseError);
      }
    }
    throw error;
  }

  throw new Error('Backend validation failed');
};
