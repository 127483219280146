import { FC } from 'react';

interface WorkwizeBannerProps {
  color?: string;
}

const WorkwizeBanner: FC<WorkwizeBannerProps> = ({ color }) => (
  <svg
    width="129"
    height="24"
    viewBox="0 0 129 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10022_10160)">
      <path
        d="M27.3187 0.748081V6.93939C27.3187 7.34286 27.1574 7.72547 26.8759 8.00721L15.0085 19.7846C14.5416 20.2472 13.7555 19.9133 13.7555 19.249V12.4976C13.7555 12.0524 13.2302 11.8298 12.9179 12.1394L7.4596 17.5585C6.99273 18.0211 6.20318 17.6872 6.20318 17.0229V11.9829C6.20318 11.3255 5.42735 10.9916 4.95705 11.4403L4.94675 11.4507L1.25299 15.1203C0.786125 15.5829 0 15.249 0 14.5846V8.39678C0 7.9933 0.161344 7.61069 0.442839 7.32895L4.06794 3.72895C4.53481 3.26634 5.32093 3.60025 5.32093 4.2646V9.3046C5.32093 9.962 6.09676 10.2959 6.56706 9.84721L6.57736 9.83678L13.5186 2.94982C13.9855 2.48721 14.7716 2.82112 14.7716 3.48547V10.2298C14.7716 10.6681 15.2968 10.8942 15.6092 10.5846L26.0657 0.215907C26.5326 -0.246702 27.3187 0.0872111 27.3187 0.751559V0.748081Z"
        fill={color}
      />
      <path
        d="M36.5565 16.7131L32.8799 5.87134H35.7532L38.0875 13.6244L40.6381 5.87134H43.4703L46.0209 13.6244L48.3552 5.87134H51.2285L47.5519 16.7131H44.4624L42.0628 9.11308L39.6426 16.7131H36.553H36.5565Z"
        fill={color}
      />
      <path
        d="M61.0191 15.3324C59.9034 16.4419 58.5509 16.9985 56.9649 16.9985C55.3789 16.9985 54.0264 16.4385 52.9004 15.3219C51.7778 14.2054 51.2148 12.8558 51.2148 11.2802C51.2148 9.70455 51.7778 8.32715 52.9004 7.19324C54.0264 6.06281 55.3789 5.49585 56.9649 5.49585C58.5509 5.49585 59.8828 6.06281 61.0088 7.19324C62.1313 8.32368 62.6943 9.68715 62.6943 11.2802C62.6943 12.8732 62.1348 14.2228 61.0191 15.3324ZM56.9649 14.435C57.7853 14.4489 58.4822 14.1532 59.0521 13.548C59.6219 12.9428 59.9068 12.195 59.9068 11.3011C59.9068 10.4072 59.6219 9.64194 59.0521 9.03324C58.4822 8.42802 57.7888 8.13237 56.9649 8.14628C56.1273 8.13237 55.427 8.42802 54.8571 9.03324C54.2873 9.63846 54.0023 10.3967 54.0023 11.3011C54.0023 12.2054 54.2873 12.9393 54.8571 13.548C55.427 14.1532 56.1273 14.4489 56.9649 14.435Z"
        fill={color}
      />
      <path
        d="M63.9679 16.7131V5.87132H66.6696V7.29393C67.5038 6.18436 68.8735 5.65219 70.7753 5.69393V8.58436C70.329 8.36523 69.8381 8.25741 69.306 8.25741C68.5851 8.25741 67.9672 8.54958 67.4454 9.13393C66.927 9.71828 66.6661 10.5148 66.6661 11.52V16.7131H63.9645H63.9679Z"
        fill={color}
      />
      <path
        d="M72.0557 16.7131V1.00879H74.7573V9.83662H76.5287L79.8139 5.8714H83.209L78.7326 11.1271L83.209 16.7131H79.8792L76.5287 12.4418H74.7573V16.7131H72.0557Z"
        fill={color}
      />
      <path
        d="M87.3593 16.7131L83.6827 5.87134H86.556L88.8904 13.6244L91.441 5.87134H94.2731L96.8237 13.6244L99.1581 5.87134H102.031L98.3548 16.7131H95.2652L92.8656 9.11308L90.4455 16.7131H87.3559H87.3593Z"
        fill={color}
      />
      <path
        d="M105.499 3.61401C105.196 3.92009 104.836 4.07314 104.417 4.07314C103.998 4.07314 103.638 3.92009 103.336 3.61401C103.034 3.30792 102.883 2.93575 102.883 2.49748C102.883 2.10444 103.034 1.75662 103.336 1.45748C103.638 1.15835 103.998 1.00879 104.417 1.00879C104.836 1.00879 105.176 1.15835 105.488 1.45748C105.797 1.75662 105.952 2.10444 105.952 2.49748C105.952 2.93575 105.801 3.30792 105.499 3.61401ZM103.099 16.7131V5.8714H105.735V16.7131H103.099Z"
        fill={color}
      />
      <path
        d="M107.013 16.7131V14.4139L113.281 8.23656H107.315V5.87134H116.958V8.17047L110.665 14.3479H117.174V16.7131H107.013Z"
        fill={color}
      />
      <path
        d="M123.573 5.54102C125.159 5.54102 126.453 6.04884 127.452 7.06449C128.454 8.08015 128.969 9.45406 128.996 11.1932C128.996 11.5445 128.99 11.8228 128.976 12.0245H120.459C120.589 12.8871 120.943 13.5549 121.52 14.028C122.096 14.501 122.78 14.741 123.573 14.741C124.839 14.741 125.814 14.2958 126.491 13.4054L128.155 14.8071C127.651 15.4923 126.988 16.0315 126.168 16.4175C125.347 16.8036 124.489 16.9984 123.597 16.9984C121.997 16.9984 120.658 16.4488 119.577 15.3462C118.495 14.2436 117.956 12.8975 117.956 11.3045C117.956 9.71145 118.492 8.29928 119.566 7.19667C120.641 6.09406 121.976 5.54449 123.576 5.54449L123.573 5.54102ZM123.593 7.66623C122.814 7.66623 122.151 7.8958 121.606 8.35493C121.056 8.81406 120.689 9.4471 120.504 10.2506H126.58C126.377 9.41928 126.02 8.77928 125.509 8.33406C124.997 7.88884 124.359 7.66623 123.597 7.66623H123.593Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_10022_10160">
        <rect width="129" height="20" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default WorkwizeBanner;
