import { createStyles, makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneInputContainer: {
      width: '100% !important',
      '& .special-label': {
        left: '8px !important',
      },
    },
    phoneInputFormControl: {
      width: '100% !important',
      borderRadius: '4px !important',
      border:
        '1px solid ' + theme.workwizeTheme.forms.inputBorder + ' !important',
      '&:hover': {
        borderColor: theme.workwizeTheme.forms.inputBorderHover + ' !important',
        borderWidth: '1px !important',
      },
      '&:focus': {
        borderColor:
          theme.workwizeTheme.forms.inputBorderFocused + ' !important',
        borderWidth: '2px !important',
        boxShadow: 'none !important',
      },
    },
  }),
);
