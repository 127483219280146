export const currencies = [
  'EUR',
  'USD',
  'CAD',
  'XCR',
  'CREDIT', //LEGACY
  'AUD',
  'GBP',
  'POUND', //LEGACY
  'CHF',
  'INR',
  'ZAR',
  'ILS',
  'SGD',
  'AED',
  'CNY',
  'KRW',
  'JPY',
] as const;

export const currenciesMenuItems: {
  value: string;
  label: string;
  disabled: boolean;
}[] = [
  { value: 'eur', label: 'EUR €', disabled: false },
  { value: 'usd', label: 'USD $', disabled: false },
  { value: 'cad', label: 'CAD $', disabled: false },
  { value: 'xcr', label: 'Credits', disabled: false },
  { value: 'credit', label: 'Credit', disabled: true }, //legacy
  { value: 'aud', label: 'AUD $', disabled: false },
  { value: 'gbp', label: 'Pound £', disabled: false },
  { value: 'pound', label: 'Pound £', disabled: true }, //legacy
  { value: 'chf', label: 'Franc ₣', disabled: false },
  { value: 'inr', label: 'INR ₹', disabled: false },
  { value: 'zar', label: 'ZAR R', disabled: false },
  { value: 'ils', label: 'ILS ₪', disabled: false },
  { value: 'sgd', label: 'SGD $', disabled: false },
  { value: 'aed', label: 'AED د.إ', disabled: false },
  { value: 'cny', label: 'CNY ¥', disabled: false },
  { value: 'krw', label: 'KRW ₩', disabled: false },
  { value: 'jpy', label: 'JPY ¥', disabled: false },
];

export type Currency = (typeof currenciesMenuItems)[number]['value'];
