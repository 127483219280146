import { z } from 'zod';

export const AddressValidatorValidatorSchema = z.object({
  ResultNumber: z.number(),
  MailabilityScore: z.number(),
  ResultPercentage: z.number(),
  FormattedAddress: z.union([z.array(z.string()), z.string()]).nullish(),
  Street: z.string().nullish(),
  HouseNumber: z.union([z.string(), z.number()]).nullish(),
  HouseNumberAddition: z.string().nullish(),
  PostalCode: z.string().nullish(),
  City: z.string().nullish(),
  Country: z.string().nullish(),
  CountryIso2: z.string().nullish(),
  CountryIso3: z.string().nullish(),
  CompanyName: z.string().nullish(),
  Building: z.string().nullish(),
  Locality: z.string().nullish(),
  Latitude: z.number(),
  Longitude: z.number(),
  State: z.string().nullish(),
});

export type AddressValidatorType = z.infer<
  typeof AddressValidatorValidatorSchema
>;
