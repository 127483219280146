import { z } from 'zod';

import {
  isEmployee,
  isEmployerAdmin,
  isEmployerManager,
  isEmployerTenant,
} from 'src/modules/user/utils/roles';
import { User } from 'src/types';

import axiosInstance from './axios';

const responseSchema = z.object({
  token: z.string(),
});

type Callback = (jwt: string) => void;

class Zendesk {
  private setupDone = false;

  private setupScript(key: string) {
    const scriptEl = document.createElement('script');

    scriptEl.id = 'ze-snippet';
    scriptEl.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    document.head.appendChild(scriptEl);
  }

  private async fetchZendeskToken(callback: Callback): Promise<void> {
    try {
      const response = await axiosInstance.get('/zendesk-token');
      const { token } = await responseSchema.parseAsync(response.data);

      callback(token);
    } catch (error) {
      console.error(error);
    }
  }

  setup(user: User) {
    if (this.setupDone) return;

    let key: string | null = null;

    if (
      isEmployerAdmin(user.role.name) ||
      isEmployerManager(user.role.name) ||
      isEmployerTenant(user.role.name)
    ) {
      key = '0f7e0c25-1941-4906-a560-fe7470f59469';
    } else if (isEmployee(user.role.name)) {
      key = '2cf46fb1-4488-4ecb-bd93-f150dd842a27';
    }

    if (key) {
      this.setupScript(key);

      window.setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.zE?.('messenger:set', 'locale', 'en-US');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.zE?.('messenger', 'loginUser', (callback: Callback) =>
          this.fetchZendeskToken(callback),
        );
        this.setupDone = true;
      }, 1000);
    }
  }

  open() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.zE?.('messenger', 'open');
  }
}

const zendeskSingleton = new Zendesk();

export default zendeskSingleton;
