import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  OrderCheckoutActionRequest,
  OrderCheckoutActionSuccess,
  OrderCheckoutActionFail,
  OrderCheckoutActionReset,
  OrderCheckoutListAction,
  BudgetTypes,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { AddressFormikValues } from 'src/types/formikValues';
import { analytic } from 'src/utils/analytic';
import axios, { ErrorResponse } from 'src/utils/axios';
import { Province } from 'src/utils/validators/province-validator';

export const processOrderCheckout =
  (
    values: Partial<
      Omit<AddressFormikValues, 'region'> & { region: Province | string }
    >,
    formikHelpers?: FormikHelpers<AddressFormikValues>,
    autoApprove?: boolean,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    OrderCheckoutListAction
  > =>
  async dispatch => {
    dispatch<OrderCheckoutActionRequest>({
      type: ActionTypes.ORDER_CHECKOUT_REQUEST,
    });

    try {
      const checkoutInfo = {
        name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
        house_number: values.house_number,
        house_number_addition: values.house_number_addition,
        building: values.building,
        street: values.street,
        city: values.city,
        country_id: values.country?.id,
        region:
          typeof values.region === 'string'
            ? values.region
            : values.region?.name,
        postal_code: values.postal_code,
        additional_address_line: values.additional_address_line,
        office_id: values.office_id,
        ...(values.tax_identification_number && {
          tax_identification_number: values.tax_identification_number,
        }),
      };

      const res = await axios.post<{ data: BudgetTypes }>(
        `${apiConfig.url}/place-order`,
        checkoutInfo,
      );

      const { data } = res.data;

      analytic.track(autoApprove ? 'Order Placed' : 'Request Placed', {
        shipping_data: checkoutInfo,
      });

      dispatch<OrderCheckoutActionSuccess>({
        type: ActionTypes.ORDER_CHECKOUT_SUCCESS,
        payload: data,
      });

      formikHelpers?.setStatus({ success: true });
      formikHelpers?.setSubmitting(false);
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;
        let nonFieldError = '';

        if (formikHelpers) {
          const formikErrors: Record<string, unknown> = {};

          if (error?.errors) {
            error.errors.forEach(err => {
              formikErrors[err.field] = err.errors[0];
              nonFieldError =
                err.field === 'non_field_error' ? err.errors[0] : '';
            });
          }

          formikHelpers.setErrors(formikErrors);
        }

        switch (e.response?.status) {
          case 422:
            enqueueSnackbar(
              nonFieldError ? nonFieldError : 'Invalid input values',
              { variant: 'error' },
            );
            break;
          case 500:
            enqueueSnackbar('Something Went Wrong. Could not resolve host.', {
              variant: 'error',
            });
            break;
          default:
            enqueueSnackbar('Server Error', { variant: 'error' });
        }

        dispatch<OrderCheckoutActionFail>({
          type: ActionTypes.ORDER_CHECKOUT_FAIL,
          payload: 'Something Went Wrong',
        });
      }
    }
  };

export const processOrderCheckoutReset = (): OrderCheckoutActionReset => ({
  type: ActionTypes.ORDER_CHECKOUT_RESET,
});
