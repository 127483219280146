import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  AddressUpdateAction,
  AddressUpdateActionFail,
  AddressUpdateActionRequest,
  AddressUpdateActionSuccess,
  AddressUpdateActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Address } from 'src/types';
import { AddressFormikValues } from 'src/types/formikValues';
import axiosInstance, { ErrorResponse } from 'src/utils/axios';

export const updateAddress =
  (
    data: AddressFormikValues,
    employeeId: string | number,
    formikHelpers?: FormikHelpers<AddressFormikValues>,
  ): ThunkAction<Promise<void>, RootState, undefined, AddressUpdateAction> =>
  async dispatch => {
    dispatch<AddressUpdateActionRequest>({
      type: ActionTypes.ADDRESS_UPDATE_REQUEST,
    });

    try {
      const address = {
        id: data.id,
        house_number: data.house_number,
        house_number_addition: data.house_number_addition,
        street: data.street,
        city: data.city,
        region: data.region,
        postal_code: data.postal_code,
        country_id: data.country?.id,
        additional_address_line: data.additional_address_line,
        building: data.building,
        phone_number: data.phone_number,
      };

      const res = data.id
        ? await axiosInstance.patch<{ data: Address }>(
            `${apiConfig.url}/employees/${employeeId}/addresses/${data.id}`,
            address,
          )
        : await axiosInstance.post<{ data: Address }>(
            `${apiConfig.url}/employees/${employeeId}/addresses/`,
            address,
          );

      const { data: shipping } = res.data;

      dispatch<AddressUpdateActionSuccess>({
        type: ActionTypes.ADDRESS_UPDATE_SUCCESS,
        payload: shipping,
      });

      enqueueSnackbar('Address Information updated', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (formikHelpers && error) {
          const formikErrors: Record<string, unknown> = {};

          if (error.errors) {
            error.errors.forEach(err => {
              if (err.field === 'country_id') {
                formikErrors.country = err.errors[0];
              }

              formikErrors[err.field] = err.errors[0];
            });
          }

          formikHelpers.setErrors(formikErrors);
        }
      }

      dispatch<AddressUpdateActionFail>({
        type: ActionTypes.ADDRESS_UPDATE_FAIL,
        payload: 'Server error',
      });

      enqueueSnackbar('Cannot update shipping info', {
        variant: 'error',
      });
    }
  };

export const updateAddressReset = (): AddressUpdateActionReset => ({
  type: ActionTypes.ADDRESS_UPDATE_RESET,
});
