import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { AddressFormikValues } from 'src/types/formikValues';

import { CountryType } from '../../../modules/countries/validators/country-response-schema';

export const initialFormValue: AddressFormikValues = {
  id: '',
  country: null,
  postal_code: '',
  house_number: '',
  building: '',
  street: '',
  city: '',
  region: '',
  house_number_addition: '',
  phone_number: '',
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    region: Yup.mixed().when('country', {
      is: (country?: CountryType) => country?.code === 'CA',
      then: Yup.object().required(
        t('common:form_validation.region_is_required'),
      ),
      otherwise: Yup.string().required(
        t('common:form_validation.region_is_required'),
      ),
    }),

    country: Yup.object()
      .required(t('common:form_validation.country_is_required'))
      .nullable(),
    postal_code: Yup.string().required(
      t('common:form_validation.postcode_is_required'),
    ),
    house_number: Yup.string(),
    street: Yup.string(),
    city: Yup.string().required(t('common:form_validation.city_is_required')),
  });

export const phoneValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    region: Yup.mixed().when('country', {
      is: (country?: CountryType) => country?.code === 'CA',
      then: Yup.object().required(
        t('common:form_validation.region_is_required'),
      ),
      otherwise: Yup.string().required(
        t('common:form_validation.region_is_required'),
      ),
    }),
    country: Yup.object()
      .required(t('common:form_validation.country_is_required'))
      .nullable(),
    postal_code: Yup.string().required(
      t('common:form_validation.postcode_is_required'),
    ),
    house_number: Yup.string().required(
      t('common:form_validation.house_number_is_required'),
    ),
    street: Yup.string().required(
      t('common:form_validation.street_is_required'),
    ),
    city: Yup.string().required(t('common:form_validation.city_is_required')),
    phone_number: Yup.string().required(
      t('common:form_validation.phone_number_is_required'),
    ),
  });
