import { FC, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import DialogModal from 'src/components/DialogModal';
import AddressSuggestions from 'src/components-for-storybook/molecules/AddressSuggestions';
import AddressForm from 'src/components-for-storybook/molecules/forms/address-form';
import usePrevious from 'src/hooks/usePrevious';
import useCountriesQuery from 'src/modules/countries/hooks/useCountriesQuery';
import { useGetEmployeeAddress } from 'src/modules/employee/hooks/useGetEmployeeAddress';
import { useUpsertEmployeeAddress } from 'src/modules/employee/hooks/useUpsertEmployeeAddress';
import { InvalidAddressError } from 'src/modules/employee/utils/validateAddress';
import { useListProvinces } from 'src/modules/province/hooks/useListProvinces';
import { Receiver } from 'src/types';
import { Address } from 'src/utils/validators/address-validator';

import {
  initialFormValue,
  phoneValidationSchema,
  validationSchema,
} from './formikConfig';

interface AddressInfoProps {
  countrySelectionDisabled?: boolean;
  countrySelectionHelperText?: string;
  userId?: string | number;
  prefillAddress?: Address;
  formId?: string;
  setHasValidationError?: (hasError: boolean) => void;
  variant?: 'primary' | 'phone';
  setShippingInfoVerified?: (value: boolean) => void;
  receiver?: Receiver;
  onSuccess?: () => void;
}

const AddressInfoForm: FC<AddressInfoProps> = ({
  countrySelectionDisabled = false,
  countrySelectionHelperText,
  userId,
  prefillAddress,
  formId = 'update-address-form',
  setHasValidationError,
  variant = 'primary',
  setShippingInfoVerified,
  receiver,
  onSuccess,
}) => {
  const {
    mutate: updateAddress,
    error: submitError,
    isLoading,
  } = useUpsertEmployeeAddress();
  const { data: countries = [] } = useCountriesQuery();

  const { t } = useTranslation(['common', 'cart']);
  const { data: address } = useGetEmployeeAddress(userId);

  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const [skipValidationModalOpen, setSkipValidationModalOpen] = useState(false);
  const [skipValidation, setSkipValidation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    values,
    errors,
    handleBlur,
    setFieldValue,
    setValues,
    submitForm,
    touched,
  } = useFormik({
    initialValues: initialFormValue,
    validationSchema:
      variant === 'phone' ? phoneValidationSchema(t) : validationSchema(t),
    onSubmit: async data => {
      if (data.country) {
        if (
          submitError instanceof InvalidAddressError &&
          !suggestionClicked &&
          !skipValidation
        ) {
          setSkipValidationModalOpen(true);
          return;
        }

        updateAddress(
          {
            employeeId: userId,
            skipValidation:
              Boolean(submitError) && !suggestionClicked && skipValidation,
            address: {
              house_number: data.house_number
                ? data.house_number
                : prefillAddress?.house_number ?? '',
              region: data.region ? data.region : prefillAddress?.region,
              building: data.building
                ? data.building
                : prefillAddress?.building,
              city: data.city ? data.city : prefillAddress?.city ?? '',
              street: data.street ? data.street : prefillAddress?.street ?? '',
              postal_code: data.postal_code
                ? data.postal_code
                : prefillAddress?.postal_code ?? '',
              country_id: data.country.id
                ? data.country.id
                : prefillAddress?.country?.id ?? '',
              country_code: data.country.code
                ? data.country.code
                : prefillAddress?.country?.code ?? '',
              phone_number: data.phone_number
                ? data.phone_number
                : prefillAddress?.phone_number,
              additional_address_line: data.additional_address_line
                ? data.additional_address_line
                : prefillAddress?.additional_address_line,
            },
          },
          {
            onSettled: () => {
              setSkipValidation(false);
              setSuggestionClicked(false);
            },
            onSuccess: () => {
              enqueueSnackbar('Address updated successfully', {
                variant: 'success',
              });
              setSkipValidationModalOpen(false);
              setHasValidationError?.(false);
              setShippingInfoVerified?.(true);
              onSuccess?.();
            },
            onError: e => {
              if (e instanceof InvalidAddressError) {
                setHasValidationError?.(true);
                if (e.suggestions.length === 0) {
                  setSkipValidationModalOpen(true);
                  setShippingInfoVerified?.(true);
                }
              }
            },
          },
        );
      }
    },
  });

  const prevCountry = usePrevious(values.country);

  useEffect(() => {
    if (address && countries.length) {
      const countryObj =
        countries.find(country => country.name === address.country?.name) ??
        null;

      setValues(prevValues => ({
        ...prevValues,
        country: countryObj,
        postal_code: address.postal_code,
        house_number: address.house_number ?? '',
        street: address.street as string,
        city: address.city,
        region: address.region ?? '',
        building: address.building,
        phone_number: address.phone_number ?? receiver?.phone ?? '',
        additional_address_line: address.additional_address_line ?? '',
      }));
    }
  }, [address, countries, receiver?.phone]);

  const getSetFieldHandler =
    (field: keyof typeof values) => (value: unknown) => {
      if (submitError instanceof InvalidAddressError) {
        setSuggestionClicked(true);
        setHasValidationError?.(false);
      }
      setFieldValue(field, value);
    };

  const basicProps = {
    country: {
      value: values.country
        ? {
            ...values.country,
            id: Number(values.country.id),
          }
        : null,
      error: touched.country && errors.country,
      onChange: getSetFieldHandler('country'),
      onBlur: handleBlur,
    },
    postalCode: {
      value: values.postal_code,
      error: touched.postal_code && errors.postal_code,
      onChange: getSetFieldHandler('postal_code'),
      onBlur: handleBlur,
    },
    city: {
      value: values.city,
      error: touched.city && errors.city,
      onChange: getSetFieldHandler('city'),
      onBlur: handleBlur,
    },
    street: {
      value: values.street,
      error: touched.street && errors.street,
      onChange: getSetFieldHandler('street'),
      onBlur: handleBlur,
    },
    houseNumber: {
      value: values.house_number,
      error: touched.house_number && errors.house_number,
      onChange: getSetFieldHandler('house_number'),
      onBlur: handleBlur,
    },
    apartment: {
      value: values.building ?? undefined,
      error: touched.building && errors.building,
      onChange: getSetFieldHandler('building'),
      onBlur: handleBlur,
    },
    province: {
      value: values.region,
      error: touched.region && errors.region,
      onChange: getSetFieldHandler('region'),
      onBlur: handleBlur,
    },
    additionalAddress: {
      value: values.additional_address_line,
      error: touched.additional_address_line && errors.additional_address_line,
      onChange: getSetFieldHandler('additional_address_line'),
      onblur: handleBlur,
    },
  };

  const { data: provinces } = useListProvinces(
    basicProps.country.value?.code ?? '',
  );

  useEffect(() => {
    if (prevCountry?.code === 'CA') {
      setFieldValue('region', address?.region ?? '');
    }

    if (values.country?.code === 'CA') {
      const selectedProvince = provinces?.data.filter(
        privince => values.region === privince.name,
      );

      setFieldValue('region', selectedProvince?.[0] ?? '');
    }
  }, [values.country]);

  return (
    <form
      id={formId}
      onSubmit={e => {
        e.preventDefault();
        submitForm();
      }}
    >
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            inset: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <DialogModal
        open={skipValidationModalOpen}
        onClose={() => setSkipValidationModalOpen(false)}
        header={{
          title: 'Address not found',
        }}
        footer={{
          buttons: [
            {
              text: 'Cancel',
              onClick: () => setSkipValidationModalOpen(false),
              variant: 'contained',
              color: 'primary',
            },
            {
              text: 'Save',
              onClick: () => {
                setSkipValidation(true);
                setSkipValidationModalOpen(false);
                submitForm();
              },
            },
          ],
        }}
      >
        <Typography>
          We couldn’t find the address through our automatic check. Are you sure
          the address is correct? A failed delivery may lead to extra costs
          incurred.
        </Typography>
      </DialogModal>

      {submitError instanceof InvalidAddressError && !suggestionClicked && (
        <Box marginBottom={2}>
          <AddressSuggestions
            error={submitError}
            onClick={suggestion => {
              setSuggestionClicked(true);
              setValues(prevState => ({
                ...values,
                city: suggestion.city,
                street: suggestion.street,
                house_number: suggestion.house_number ?? prevState.house_number,
                postal_code: suggestion.postal_code,
                region: suggestion.region ?? prevState.region,
                apartment: suggestion.building,
              }));
            }}
          />
        </Box>
      )}

      {variant === 'primary' && (
        <AddressForm
          countrySelectionDisabled={countrySelectionDisabled}
          countrySelectionHelperText={countrySelectionHelperText}
          type="primary"
          countries={countries}
          provinces={provinces?.data}
          {...basicProps}
        />
      )}

      {variant === 'phone' && (
        <AddressForm
          type="phone"
          countrySelectionDisabled={countrySelectionDisabled}
          countrySelectionHelperText={countrySelectionHelperText}
          countries={countries}
          provinces={provinces?.data}
          phone={{
            value: values.phone_number ?? '',
            error: touched.phone_number && errors.phone_number,
            onChange: getSetFieldHandler('phone_number'),
            onBlur: handleBlur,
          }}
          {...basicProps}
        />
      )}
    </form>
  );
};

export default AddressInfoForm;
