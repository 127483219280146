import { z } from 'zod';

import { currencies } from '../currencies';

export const CurrencyCodeEnum = z.enum(currencies);

export const currencyValidator = z.object({
  id: z.union([z.string(), z.number()]),
  icon: z.string(),
  name: CurrencyCodeEnum,
});

export const listCurrencyValidator = z.array(currencyValidator);
