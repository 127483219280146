import { z } from 'zod';

import { budgetValidator } from 'src/utils/validators/budget-validator';

import { categoryValidator } from '../../categories/validators/category-response-schema';

export const departmentValidator = z.object({
  id: z.number(),
  name: z.string(),
  rent_budget: z.number(),
  purchase_budget: z.number(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  employees_count: z.number(),
  budgets: z.array(budgetValidator).nullish(),
  auto_approve_orders: z.boolean(),
  auto_offboarding: z.boolean(),
  auto_offboarded_categories: z.array(categoryValidator).nullish(),
  employees_without_personal_info_count: z.number().nullish(),
  user_id: z.number().nullish(),
});

export const indexDepartmentValidator = z.array(departmentValidator);
export type Department = z.infer<typeof departmentValidator>;
